import React from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import HotelOverview from "../components/hotelOverview"
import QuickSelect from "../components/quickSelect"
import { renderDynamicComponents } from "../components"
import BigHeader from "../components/bigHeader"
// import PromotionModal from "../components/promotionModal"

const IndexPage = ({data}) => {
  const content = data.allContentfulLieblingsplatzHome.nodes[0]
  return (
    <Layout theme="lieblingsplatz">
      <SEO title="Lieblingsplatz Hotels" />
      <BigHeader 
        image={content.headerImage.fluid} 
        alt={content.headerImage.description} 
        additionalClass="home"
        strer={content.strerImHeader}
      />
      <QuickSelect root={true} />

      <section id="hotel_overview">
        <div className="color_invert pt-5">
          <h1 className="handwritten h2 text-center">{content.title}</h1>
        </div>
      </section>

      <HotelOverview additionalClass="hotelOverview my-5" />

      {content && renderDynamicComponents(content.components, 'lieblingsplatz')}
      
      {/* <PromotionModal
        title="Willkommen zurück!"
        content="Lorem ipsum dolor set"
      /> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
query lieblingsplatzHomeQuery {
  allContentfulLieblingsplatzHome {
    nodes {
      id
      title
      strerImHeader {
        message
        prefix
        suffix
        link
      }
      headerImage {
        fluid(maxWidth: 1600 quality: 80) {
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
          aspectRatio
        }
        description
      }
      components {
        ... on ContentfulComponentThreeTeaser {
          id
          threeTeaserContent1
          threeTeaserContent2
          threeTeaserContent3
          threeTeaserIcon1
          threeTeaserIcon2
          threeTeaserIcon3
          contenTeaserTitle1
          threeTeaserTitle2
          threeTeaserTitle3
threeTeaserLink1 {
        ... on ContentfulLink {
          id
          title
          linkTarget
        }
        ... on ContentfulDocument {
          id
          title
          document {
            file {
              url
            }
          }
        }
      }
      threeTeaserLink2 {
        ... on ContentfulLink {
          id
          title
          linkTarget
        }
        ... on ContentfulDocument {
          id
          title
          document {
            file {
              url
            }
          }
        }
      }
      threeTeaserLink3 {
        ... on ContentfulLink {
          id
          title
          linkTarget
        }
      }
      darkBackground
          internal {
            type
          }
        }
        ... on ContentfulComponentSpecialOffer {
          seekdaOfferId
          offerSubTitle
          OfferTitle
          OfferContent {
            json
          }
          OfferImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          internal {
            type
          }
        }
... on ContentfulComponentTwoTeaser {
          OfferTitle
          OfferContent {
            json
          }
          OfferImage {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          linkText
          linkTarget
          offerTitle2
          offerContent2 {
            json
          }
          offerImage2 {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
          }
          linkText2
          linkTarget2
          internal {
            type
          }
        }
        ... on ContentfulComponentImageWall {
          id
          imageWallImages {
            fluid {
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              aspectRatio
            }
            title
          }
          internal {
            type
          }
        }
        ... on ContentfulComponentGeneralContentFullWidth {
          textCentered
          twoColumns
          handwritten
          contenTeaserTitle
          darkBackground
          GeneralContentFullWidthContent {
            json
          }
          internal {
            type
          }
          contentIdOptional
        }
... on ContentfulComponentFourTeaser {
          id
          text1
          text2
          text3
          text4
          icon1
          icon2
          icon3
          icon4
          title
          title1
          title2
          title3
          title4
          internal {
            type
          }
        }
        ... on ContentfulComponentFourTeaser {
          id
          text1
          text2
          text3
          text4
          icon1
          icon2
          icon3
          icon4
          title
          title1
          title2
          title3
          title4
          internal {
            type
          }
        }
      }
    }
  }
}
`