import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from 'gatsby-image'
import { Container, Row } from "react-bootstrap"

const HotelOverview = ({ additionalClass }) => {

  const { ben, bra, rue, spo, tir, tra, gro, bra2, hgs } = useStaticQuery(
    graphql`
    query {
      ben: file(relativePath: { eq: "home/Start_neu_BEN.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bra: file(relativePath: { eq: "home/Start_neu_Braunfels.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rue: file(relativePath: { eq: "home/Start_neu_RUEG.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spo: file(relativePath: { eq: "home/Start_neu_SPO.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tir: file(relativePath: { eq: "home/Start_neu_TIR.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tra: file(relativePath: { eq: "home/Start_neu_TRA.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      gro: file(relativePath: { eq: "home/GRO.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bra2: file(relativePath: { eq: "home/BRA2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hgs: file(relativePath: { eq: "home/HGS.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
    }
    `
  )
  
  return (
    <section className={`component bigHeaderMasonry ${additionalClass}`}>
      <Container fluid>
        <Row>
          <div className="col-md-4 item">
            <div className="strandhotel">
              <Link to={'/strandhotel-st-peter-ording/'}>
                <Image fluid={spo.childImageSharp.fluid} alt="Lieblingsplatz Hotels St. Peter-Ording" />
                <div className="contentWrapper">
                  <h2 className="h4 text-uppercase">St. Peter-Ording · Nordsee</h2>
                  <p>Dein Lieblingsplatz in St. Peter-Ording an der Nordsee. Strand und Meer soweit das Auge reicht. Die wohl größte Sandkiste Europas.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="strandperle">
              <Link to={'/hotel-strandperle-travemuende/'}>
                <Image fluid={tra.childImageSharp.fluid} alt="Lieblingsplatz Hotels Travemünde" />
                <div className="contentWrapper">
                  <h2 className="h4 text-uppercase">Travemünde · Ostsee</h2>
                  <p>Dein Lieblingsplatz in Travemünde an der Ostsee. Erste Reihe Meerblick. Unsere Perle direkt am Strand.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="insellodge">
              <Link to={'/hotel-bohlendorf-ruegen/'}>
                <Image fluid={rue.childImageSharp.fluid} alt="Lieblingsplatz Hotels Rügen" />
                <div className="contentWrapper">
                  <h2 className="h4 text-uppercase">Rügen · Ostseeinsel</h2>
                  <p>Dein Lieblingsplatz auf der Ostsee-Insel Rügen. Natur pur und traumhafte Strände. Eine Oase der Ruhe.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="meinsbur">
              <Link to={'/hotel-meinsbur-bendestorf/'}>
                <Image fluid={ben.childImageSharp.fluid} alt="Lieblingsplatz Hotels Bendestorf" />
                <div className="contentWrapper">
                  <h2 className="h4 text-uppercase">Bendestorf · Lüneburger Heide</h2>
                  <p>Dein Lieblingsplatz Boutique Hotel in der Lüneburger Heide. Urbaner Lifestyle und Fusionsküche unter traditionellem Reet.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="braunfels">
              <Link to={'/schlosshotel-braunfels/'}>
                <Image fluid={bra.childImageSharp.fluid} alt="Lieblingsplatz Hotels Braunfels" />
                <div className="contentWrapper">
                  <h2 className="h4 text-uppercase">Braunfels · Lahntal</h2>
                  <p>Dein Lieblingsplatz in Braunfels im Lahntal, Hessen. Savoir-vivre. Unser Schlosshotel im neuen Gewand.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="tirol">
              <Link to={'/hotel-tirol/'}>
                <Image fluid={tir.childImageSharp.fluid} alt="Lieblingsplatz Hotels Tirol" />
                <div className="contentWrapper">
                  <h2 className="h4 text-uppercase">Zell am Ziller · Tirol</h2>
                  <p>Dein Lieblingsplatz im Zillertal in den Tiroler Alpen. Mitten in der Zillertal Arena. Aktiv und vital.</p>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="comingsoon">
              <Image fluid={bra2.childImageSharp.fluid} alt="Lieblingsplatz Hotels" />
              <div className="contentWrapper">
                <h2 className="h4 text-uppercase">Coming soon · Hessen</h2>
                <p>Sommer 2021</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="comingsoon">
              <Image fluid={gro.childImageSharp.fluid} alt="Lieblingsplatz Hotels" />
              <div className="contentWrapper">
                <h2 className="h4 text-uppercase">Coming soon · Ostsee</h2>
                <p>Ende 2022</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 item">
            <div className="comingsoon">
              <Image fluid={hgs.childImageSharp.fluid} alt="Lieblingsplatz Hotels" />
              <div className="contentWrapper">
                <h2 className="h4 text-uppercase">Coming soon · Hessen</h2>
                <p>Winter 2021</p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default HotelOverview
